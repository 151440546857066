const filters = document.querySelector("[data-filters]");

const getFilterData = filterElements => {
	let obj = {};

	filterElements.forEach(target => {
		const { type, name, value } = target;

		if (type === "radio" || type === "checkbox") {
			// Handle checked items
			if (target.checked) {
				if (!obj[name]) {
					obj[name] = [];
				}
				obj[name].push(value);
			}
		} else {
			obj[name] = value;
		}
	});

	return obj;
};

const obj2QueryString = obj => {
	return Object.keys(obj)
		.map(key => {
			let val = obj[key];

			if (Array.isArray(val)) {
				return val
					.map(v => {
						return encodeURIComponent(key) + "=" + encodeURIComponent(v);
					})
					.join("&");
			} else {
				return encodeURIComponent(key) + "=" + encodeURIComponent(val);
			}
		})
		.join("&");
};

const queryString2Obj = str => {
	if (!str.length) return {};
	const arr = str.split("&");
	const obj = arr.reduce((o, v) => {
		const [key, val] = v.split("=");
		if (!o[key]) {
			o[key] = [];
		}
		o[key].push(decodeURIComponent(val));
		return o;
	}, {});
	return obj;
};

const clearOtherCheckboxes = (checkboxName, currentCheckbox) => {
	// Get all the checkboxes except the current one
	const checkboxes = document.querySelectorAll(`input[name='${checkboxName}']:not(#${currentCheckbox.id})`);
	
	checkboxes.forEach(checkbox => {
		checkbox.checked = false; // Uncheck all other checkboxes
	});
};

const getContent = filterElements => {
	const data = getFilterData(filterElements);
	const newQ = obj2QueryString(data);

	const oldListingContainer = document.querySelector("[data-filtered-content]");
	const loader = document.createElement("div");
	loader.setAttribute("class", "loader");
	oldListingContainer.prepend(loader);

	const newLocation = location.origin + location.pathname + "?" + newQ;

	fetch(newLocation)
		.then(response => {
			history.pushState("", "", newLocation);
			return response.text();
		})
		.then(data => {
			const parser = new DOMParser();
			const htmlResponse = parser.parseFromString(data, "text/html");
			const newListingContainer = htmlResponse.documentElement.querySelector(
				"[data-filtered-content]"
			);
			oldListingContainer.innerHTML = newListingContainer.innerHTML;

			init_calendars();
		})
		.catch(error => {
			console.log("Error:", error);
		});
};

const initListeners = (filterElements) => {
	Array.prototype.forEach.call(filterElements, (element) => {
	  element.addEventListener(
		"change",
		(event) => {
		  const { name, value, checked } = event.target;
		  const allCategoriesCheckbox = document.querySelector(
			`input[name='${name}'][value='null']`
		  );
		  const allCategoriesLabel = document.querySelector(`label[for='all']`);
  
		  // Uncheck all other checkboxes if one is selected
		  filterElements.forEach((el) => {
			if (el !== event.target && el.type === "checkbox" && el.name === name) {
			  el.checked = false;
			  const otherLabel = document.querySelector(`label[for='${el.id}']`);
			  if (otherLabel) {
				otherLabel.classList.remove("bg-stem", "text-stone");
				otherLabel.classList.add(
				  "border-stem",
				  "bg-transparent",
				  "text-stem"
				);
			  }
			}
		  });
  
		  // Update the styling of the current checkbox
		  if (value === "null" && checked) {
			// If "All categories" is selected
			// Apply the highlighted styles to "All categories"
			if (allCategoriesLabel) {
			  allCategoriesLabel.classList.add("bg-stem", "text-stone");
			}
		  } else if (value !== "null" && checked) {
			// If a specific category is selected
			// Apply the highlighted style to the selected category
			const categoryLabel = document.querySelector(
			  `label[for='${event.target.id}']`
			);
			if (categoryLabel) {
			  categoryLabel.classList.add("bg-stem", "text-stone");
			  categoryLabel.classList.remove(
				"border-stem",
				"bg-transparent",
				"text-stem"
			  );
			}
		  }
  
		  // Update the content
		  getContent(filterElements);
		},
		false
	  );
	});
  
	document.body.addEventListener("click", function (event) {
	  if (event.target.dataset.filtersUpdate != undefined) {
		getContent(filterElements);
	  }
	});
  
	const submitButtons = document.querySelectorAll("button[type='submit']");
	submitButtons.forEach((button) => {
	  button.addEventListener(
		"click",
		(event) => {
		  event.preventDefault();
		  getContent(filterElements);
		},
		false
	  );
	});
  
	const buttonButtons = document.querySelectorAll("button[type='button'].option");
	buttonButtons.forEach((button) => {
	  button.addEventListener(
		"click",
		(event) => {
		  setTimeout(() => {
			event.preventDefault();
			getContent(filterElements);
		  }, 200);
		},
		false
	  );
	});
  };
  


const checkUrlParams = filterElements => {
    // Parse the query string into an object
    const queryParams = queryString2Obj(window.location.search.substring(1));

    // Get reference to "All categories" checkbox and label
    const allCategoriesCheckbox = document.querySelector(`input[name='categories'][value='null']`);
    const allCategoriesLabel = document.querySelector(`label[for='all']`);

    // If there are categories in the URL, pre-check them
    if (queryParams['categories']) {
        const categories = queryParams['categories'];
        let isAnyCategoryChecked = false;

        // Loop through all filter elements
        filterElements.forEach(element => {
            if (element.type === 'checkbox' && element.name === 'categories') {
                // If the element value is in the URL params, check it
                if (categories.includes(element.value)) {
                    element.checked = true;
                    isAnyCategoryChecked = true;
                    // Add the styles for checked items
                    element.parentElement.querySelector('label').classList.add('bg-stem', 'text-stone');
                }
            }
        });

        // If any category is checked, uncheck "All categories" and remove its styles
        if (isAnyCategoryChecked && allCategoriesCheckbox) {
            allCategoriesCheckbox.checked = false;
            allCategoriesLabel.classList.remove('bg-stem', 'text-stone');
        }

    } else {
        // If no categories are in the URL, default to "All categories"
        if (allCategoriesCheckbox) {
            allCategoriesCheckbox.checked = true;
            allCategoriesLabel.classList.add('bg-stem', 'text-stone');
        }
    }
};


const initFilters = filters => {
	if (!filters) {
        return;
    }

    const filterElements = filters.querySelectorAll(`select, input`);

    if (!filterElements.length) {
        return;
    }

    // Pre-check the checkboxes based on URL parameters
    checkUrlParams(filterElements);
    
    // Initialize listeners for changes
    initListeners(filterElements);
};

initFilters(filters);
