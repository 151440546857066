import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Initialize Tippy.js for all elements with a 'data-tooltip' attribute
tippy('[data-tooltip]', {
  content(reference) {
    return reference.getAttribute('data-tooltip');  // Set the tooltip content to the value of 'data-tooltip'
  },
  placement: 'top-start', // Set the tooltip placement
  trigger: 'mouseenter focus',  // Tooltip will appear on hover or focus
  onShow(instance) {
    // Add custom classes to the tooltip content and popper (tooltip container)
    instance.popper.querySelector('.tippy-box').classList.add('p-2', 'bg-leaf', 'text-black');
  },
  arrow: false, // Disable the arrow if you want a flat tooltip
});




// Initialize Tippy.js for all anchor tags inside rich text
document.querySelectorAll('.richtext a').forEach(anchor => {
  const href = anchor.getAttribute('href');

  // Only initialize Tippy if href is not empty or undefined
  if (href) {
    tippy(anchor, {
      content: href, // Set the tooltip content to the href value
      placement: 'top-start', // Set the tooltip placement
      trigger: 'mouseenter focus', // Tooltip will appear on hover or focus
      onShow(instance) {
        // Add the custom class for the specific case
        instance.popper.querySelector('.tippy-box').classList.add('custom-richtext-tooltip');
      },
      arrow: false, // Disable the arrow for a flat tooltip
    });
  }
});