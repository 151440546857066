import Swiper from "swiper/bundle";
import "swiper/css/bundle";

var stats = new Swiper(".stats", {
  slidesPerView: "1.85",
  spaceBetween: 24,
  loop: true,
  centeredSlides: true,
  autoplay: {
    enabled: true,
    delay: 6000,
  },
  breakpoints: {
    1024: {
      slidesPerView: 4,
      spaceBetween: 0,
      loop: false,
      centeredSlides: false,
      autoplay: {
        enabled: false,
      },
    },
    1280: {
      slidesPerView: 4,
      spaceBetween: 96,
      loop: false,
      centeredSlides: false,
      autoplay: {
        enabled: false,
      },
    },
  },
});

const homeCarousel = new Swiper(".home-carousel", {
  slidesPerView: "1.25",
  spaceBetween: 24,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  navigation: {
    prevEl: ".home-prev",
    nextEl: ".home-next",
  }
});

var thumbs = new Swiper(".thumbnail", {
  spaceBetween: 10,
  slidesPerView: "4.25",
  watchSlidesProgress: true,
});

var gallery = new Swiper(".gallery", {
  spaceBetween: 16,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: thumbs,
  },
});

var logoCarousel = new Swiper(".logo-carousel", {
  slidesPerView: "auto",
  spaceBetween: 40,
  loop: true,
  breakpoints: {
    768: {
      spaceBetween: 64,
    },
    1024: {
      spaceBetween: 80,
    },
  },
  navigation: {
    prevEl: ".prev-logo",
    nextEl: ".next-logo",
  },
});

var story = new Swiper(".story-carousel", {
  slidesPerView: "1.25",
  spaceBetween: 24,
  loop: true,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  breakpoints: {
    768: {
      slidesPerView: "1.5",
      spaceBetween: 48,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
    1024: {
      spaceBetween: 48,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
    1280: {
      spaceBetween: 80,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 64,
    },
  },
  navigation: {
    prevEl: ".prev-story",
    nextEl: ".next-story",
  },
});

var travel = new Swiper(".travel-carousel", {
  slidesPerView: "1.25",
  spaceBetween: 24,
  loop: true,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  breakpoints: {
    480: {
      slidesPerView: "1.75",
      spaceBetween: 48,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
    1024: {
      slidesPerView: "2.5",
      spaceBetween: 48,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
    1280: {
      slidesPerView: "1.5",
      spaceBetween: 96,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 64,
    },
  },
  navigation: {
    prevEl: ".prev-travel",
    nextEl: ".next-travel",
  },
});

var columns = new Swiper(".columns-carousel", {
  slidesPerView: "1.25",
  spaceBetween: 16,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  navigation: {
    prevEl: ".prev-column",
    nextEl: ".next-column",
  },
});

var testimonials = new Swiper(".testimonials", {
  slidesPerView: "1.25",
  loop: true,
  spaceBetween: 24,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  breakpoints: {
    768: {
      slidesPerView: "1.75",
      spaceBetween: 24,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
    1024: {
      slidesPerView: "1.5",
      initialSlide: 2,
      spaceBetween: 32,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 0
    },
    1600: {
      slidesPerView: "2.125",
      initialSlide: 2,
      spaceBetween: 32,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 0
    }
  },
  navigation: {
    prevEl: ".prev-testimonial",
    nextEl: ".next-testimonial",
  },
});

var testimonialRow = new Swiper(".testimonialRow", {
  slidesPerView: "1.25",
  loop: true,
  spaceBetween: 24,
  breakpoints: {
    768: {
      slidesPerView: "1.75",
      spaceBetween: 24,
      slidesOffsetBefore: 48,
      slidesOffsetAfter: 48,
    },
    1024: {
      slidesPerView: "2.5",
      spaceBetween: 32,
      centeredSlides: true
    },
    1600: {
      slidesPerView: "3.5",
      spaceBetween: 32,
      centeredSlides: true
    }
  },
  navigation: {
    prevEl: ".prev-testimonialRow",
    nextEl: ".next-testimonialRow",
  },
});

var testimonialBlock = new Swiper(".testimonials-block", {
  slidesPerView: "1.25",
  loop: true,
  spaceBetween: 24,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
  },
  autoplay: {
    enabled: true,
    delay: 3000,
  },
  navigation: {
    prevEl: ".prev-testimonial-block",
    nextEl: ".next-testimonial-block",
  },
});
